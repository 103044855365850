<template>
  <div>
    <div class="row">
      <div class="col-sm-12">
        <div class="card">
          <div class="card-header">
            <div class="row">
              <div class="col-md-4 d-flex justify-content-between align-items-center">
                <div class="header-title me-5">
                  <h4 class="card-title">Курсы валют</h4>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#currency-modal"
                >
                  Добавить
                </button>
              </div>
            </div>
          </div>
          <div class="card-body px-0">
            <div v-if="currencies" class="table-responsive">
              <table id="user-list-table" class="table" role="grid" data-toggle="data-table">
                <thead>
                <tr class="light">
                  <th v-for="(header, index) in tableHeaders"
                      :key="index"
                  >
                    {{ header }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(currency, index) in currencies" :key="index">
                  <td>
                    <input v-model="currency.name" class="form-control" type="text">
                  </td>
                  <td>
                    <input v-model="currency.value" class="form-control" type="text">
                  </td>
                  <td class="text-end">
                    <button class="btn btn-link text-danger" @click="updateCurrency(currency.id)">Сохранить</button>
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      ref="currenciesModal"
      mainClass="fade" :tabindex="-1" id="currency-modal"
      ariaLabelled="addModalLabel" :ariaHidden="true"
      dialog-class="modal-lg modal-dialog-scrollable"
    >
      <modal-header :dismissable="true"></modal-header>
      <modal-body class="px-5">
        <h1 class="text-center">Новая валюта</h1>

        <Form class="row mt-4" v-slot="{ meta }" @submit="addCurrency">
          <div class="col-md-6">
            <label for="name" class="form-label">Наеминование</label>
            <Field id="name" type="text" class="form-control" name="name" />
            <ErrorMessage name="name" class="text-danger" />
          </div>

          <div class="col-md-6">
            <label for="value" class="form-label">Значения</label>
            <Field id="value" type="text" class="form-control" name="value" />
            <ErrorMessage name="value" class="text-danger" />
          </div>

          <div class="d-flex align-items-center justify-content-center mt-4">
            <button type="submit" class="btn btn-primary" :disabled="!(meta.valid && meta.dirty)">Сохранить</button>
          </div>
        </Form>
      </modal-body>
    </modal>
  </div>
</template>

<script>
import Modal from '@/components/bootstrap/modal/modal'
import ModalHeader from '@/components/bootstrap/modal/model-header'
import ModalBody from '@/components/bootstrap/modal/model-body'
import { Form, Field, ErrorMessage } from 'vee-validate'
import { getCurrencies, createCurrency } from '@/services/currencies.service'

export default {
  name: 'Index',
  data: () => ({
    tableHeaders: [
      'Наеминование',
      'Значения',
      'Действия'
    ],
    currencies: null
  }),
  components: {
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    Field,
    ErrorMessage
  },
  methods: {
    async fetchCurrencies () {
      const { data: currencies } = await getCurrencies()
      this.currencies = currencies
    },
    async addCurrency (values) {
      const formData = new FormData()
      formData.append(`currencies[${values.name}]`, String(values.value))

      await createCurrency(formData)
      window.location.reload()
    },
    async updateCurrency (id) {
      const currency = this.currencies.find(currency => currency.id === id)

      const formData = new FormData()
      formData.append('id', id)
      formData.append(`currencies[${currency.name}]`, String(currency.value))

      await createCurrency(formData)
      await this.fetchCurrencies()
    }
  },
  async created () {
    await this.fetchCurrencies()
  }
}
</script>

<style scoped>

</style>
