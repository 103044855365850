import ApiClient from '../api'
import { Currency } from '../models'

const api = new ApiClient(Currency.modelName)

export const getCurrencies = async (params) => {
  const { data: currencies } = await api.getAll(params)

  return currencies
}

export const getCurrency = async (id) => {
  const { data: currency } = await api.getOne(id)

  return currency
}

export const createCurrency = async (formData) => {
  const { data: currency } = await api.create(formData)

  return currency
}
